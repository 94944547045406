import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Flex,
  VStack,
  Text,
  Link as ChakraLink,
  useColorMode,
  useColorModeValue,
  IconButton,
  Badge,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from '@chakra-ui/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartPie,
  faCog,
  faChartBar,
  faBullhorn,
  faHeadset,
  faFileInvoiceDollar,
  faBook,
  faList,
  faBell,
  faUserCircle,
  faMoneyBillWave,
  faTable,
  faEnvelope,
  faWallet,
  faCloudSun,
  faClock,
  faEnvelopeOpen,
  faMoon,
  faSun,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
import { faWeebly } from '@fortawesome/free-brands-svg-icons';
import { UseDataUserContext } from '../../_Hooks/_Context/useDataUserAuthenticated';
import BotWhatsappModule from '../Private/chat/BotWhatsappModule';
import axios from 'axios';
import WeatherComponent from './WeatherComponent';
import { ApiConsume } from '../../_API/ApiConsume';

const navItems = [
  { title: "Dashboard", link: '/', icon: faChartPie },
  { title: "Configuración", link: "/panel/configuración", icon: faCog },
  { title: "Finanzas", link: "/panel/finanzas", icon: faChartBar },
  { title: "Movimientos", link: "/panel/movimientos", icon: faBullhorn },
  { title: "Servicios", link: "/panel/servicios", icon: faHeadset },
  { title: "Liquidación", link: "/panel/liquidación", icon: faFileInvoiceDollar },
  { title: "Reportes", link: "/panel/reportes", icon: faTable },
  { title: "Cuentas Corrientes", link: "/panel/ctas_ctes", icon: faWallet },
  { title: "Sueldos", link: "/panel/sueldos", icon: faMoneyBillWave },
  { title: "Correo Electronico", link: "/panel/correo", icon: faEnvelope },
  {
    title: "Manual",
    icon: faBook,
    children: [
      { title: "Procedimientos", link: "/panel/manual-de-procedimiento" },
      { title: "Videos Instructivos", link: "/panel/manual/instructional-videos" }
    ]
  },
  { title: "Requerimientos", link: "/panel/requerimientos", icon: faList },
  { title: "Active Users UF", link: "/panel/active-users-uf", icon: faWeebly, onlySuperUser: true },
];

export const AdminSidebarApp = () => {
  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();
  const { useInfoUserAuth } = useContext(UseDataUserContext);
  const userIsSuperuser = useInfoUserAuth.data?.is_superuser || false;
  const bgSidebar = useColorModeValue("gray.100", "gray.800");
  const colorSidebar = useColorModeValue("gray.700", "gray.200");

  const handleMouseEnter = () => setCollapsed(false);
  const handleMouseLeave = () => setCollapsed(true);

  return (
    <Box
      as="nav"
      height="100vh"
      bg={bgSidebar}
      color={colorSidebar}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      w={collapsed ? "80px" : "200px"}
      transition="width 0.2s ease"
      display="flex"
      flexDirection="column"
    >
      {/* Contenedor de la navegación con scroll vertical */}
      <Box flex="1" overflowY="auto">
        <VStack align="stretch" spacing={2} p={1}>
          {/* Logo o encabezado */}
          <Box p={3} textAlign="center">
            <Text fontWeight="bold" fontSize="lg">WAM</Text>
          </Box>

          {/* Items del menú */}
          {navItems
            .filter(item => !item.onlySuperUser || userIsSuperuser)
            .map((item) => {
              if (item.children) {
                if (collapsed) {
                  return (
                    <Popover key={item.title} placement="right" trigger="hover">
                      <PopoverTrigger>
                        <Box
                          as={ChakraLink}
                          display="flex"
                          alignItems="center"
                          py={2}
                          px={2}
                          borderRadius="md"
                          _hover={{
                            textDecoration: "none",
                            bg: useColorModeValue("gray.200", "gray.700"),
                          }}
                        >
                          <Box minW="24px" textAlign="center">
                            <FontAwesomeIcon icon={item.icon} />
                          </Box>
                        </Box>
                      </PopoverTrigger>
                      <PopoverContent w="auto">
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader>{item.title}</PopoverHeader>
                        <PopoverBody>
                          <VStack align="stretch" spacing={1}>
                            {item.children.map((child) => (
                              <ChakraLink
                                as={Link}
                                to={child.link}
                                key={child.link}
                                display="block"
                                py={1}
                                px={2}
                                borderRadius="md"
                                _hover={{
                                  textDecoration: "none",
                                  bg: useColorModeValue("gray.200", "gray.700"),
                                }}
                              >
                                <Text>{child.title}</Text>
                              </ChakraLink>
                            ))}
                          </VStack>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  );
                } else {
                  return (
                    <Box key={item.title}>
                      <ChakraLink
                        as={Link}
                        to="#"
                        display="flex"
                        alignItems="center"
                        py={2}
                        px={2}
                        borderRadius="md"
                        _hover={{
                          textDecoration: "none",
                          bg: useColorModeValue("gray.200", "gray.700"),
                        }}
                      >
                        <Box minW="24px" textAlign="center">
                          <FontAwesomeIcon icon={item.icon} />
                        </Box>
                        {!collapsed && <Text ml={2}>{item.title}</Text>}
                      </ChakraLink>
                      <VStack align="stretch" pl={8} spacing={1}>
                        {item.children.map((child) => {
                          const childActive = location.pathname === child.link;
                          return (
                            <ChakraLink
                              as={Link}
                              to={child.link}
                              key={child.link}
                              display="block"
                              py={1}
                              px={2}
                              borderRadius="md"
                              bg={childActive ? "blue.600" : "transparent"}
                              color={childActive ? "white" : "inherit"}
                              _hover={{
                                textDecoration: "none",
                                bg: childActive
                                  ? "blue.700"
                                  : useColorModeValue("gray.200", "gray.700"),
                              }}
                            >
                              <Text>{child.title}</Text>
                            </ChakraLink>
                          );
                        })}
                      </VStack>
                    </Box>
                  );
                }
              } else {
                const isActive = location.pathname === item.link;
                return (
                  <ChakraLink
                    as={Link}
                    to={item.link}
                    key={item.link}
                    display="flex"
                    alignItems="center"
                    py={2}
                    px={2}
                    borderRadius="md"
                    bg={isActive ? "blue.600" : "transparent"}
                    color={isActive ? "white" : "inherit"}
                    _hover={{
                      textDecoration: "none",
                      bg: isActive ? "blue.700" : useColorModeValue("gray.200", "gray.700"),
                    }}
                  >
                    <Box minW="24px" textAlign="center">
                      <FontAwesomeIcon icon={item.icon} />
                    </Box>
                    {!collapsed && <Text ml={2}>{item.title}</Text>}
                  </ChakraLink>
                );
              }
            })}
        </VStack>
      </Box>

      {/* Módulo BOT WhatsApp fijo en la parte inferior */}
      <Box p={1}>
        <BotWhatsappModule collapsed={collapsed} />
      </Box>
    </Box>
  );
};

export default AdminSidebarApp;

export const HeaderNavbar = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const bgHeader = useColorModeValue("blue.600", "blue.900");
  const { useInfoUserAuth } = useContext(UseDataUserContext);
  const token = localStorage.getItem('USER_AUTH') || sessionStorage.getItem('USER_AUTH') || '';
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]);
  const [online, setOnline] = useState(navigator.onLine);
  const [time, setTime] = useState(new Date());

  const handleLogout = () => {
    ApiConsume('USERS__AUTH_LOGOUT_USER', 'POST', { refresh_token: token })
      .then(() => {
        localStorage.removeItem('USER_AUTH');
        sessionStorage.removeItem('USER_AUTH');
        window.location.href = '/';
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Actualiza el reloj cada segundo
  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  // Detectar online/offline
  useEffect(() => {
    const goOnline = () => setOnline(true);
    const goOffline = () => setOnline(false);
    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);
    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
    };
  }, []);

  // Fetch de correos no leídos
  useEffect(() => {
    if (!token) return;
    fetchUnreadEmails();
    const interval = setInterval(() => {
      fetchUnreadEmails();
    }, 60000); // cada 1 minuto
    return () => clearInterval(interval);
  }, [token]);

  const fetchUnreadEmails = async () => {
    try {
      const res = await axios.get('/emails/list/inbox/?unread=1', {
        headers: { Authorization: `Bearer ${token}` }
      });
      const newEmails = res.data.map((email) => ({
        id: `email-${email.id}`,
        message: `Nuevo correo: ${email.subject}`,
        read: !!email.is_read,
        link: `/panel/Correo/email/${email.id}`,
        type: 'email'
      }));

      setNotifications((prev) => {
        const prevWithoutEmails = prev.filter((n) => n.type !== 'email');
        return [...prevWithoutEmails, ...newEmails];
      });
    } catch (error) {
      console.error("Error fetching unread emails", error);
    }
  };

  // Marcar todas como leídas
  const markAllAsRead = () => {
    setNotifications((prev) => prev.map((n) => ({ ...n, read: true })));
  };

  // Manejar click en notificaciones
  const handleNotificationClick = (idx) => {
    const notif = notifications[idx];
    setNotifications((prev) => {
      const updated = [...prev];
      updated[idx].read = true;
      return updated;
    });
    if (notif.link) navigate(notif.link);
  };

  const unreadCount = notifications.filter((n) => !n.read).length;

  return (
    <Flex
      as="header"
      bg={bgHeader}
      color="white"
      align="center"
      justify="space-between"
      px={4}
      py={2}
      shadow="md"
    >
      {/* Lado izquierdo: logo o nombre del sistema */}
      <Box>
        <Text fontWeight="bold">WAM Soluciones</Text>
      </Box>

      {/* Lado derecho: reloj, clima, notificaciones y perfil */}
      <Flex align="center">
        <IconButton
          aria-label="Toggle color mode"
          icon={<FontAwesomeIcon icon={colorMode === 'light' ? faMoon : faSun} />}
          variant="ghost"
          colorScheme="whiteAlpha"
          mr={4}
          onClick={toggleColorMode}
        />

        <Badge colorScheme={online ? "green" : "red"} mr={4}>
          {online ? "Online" : "Offline"}
        </Badge>

        <HStack spacing={2} mr={4}>
          <FontAwesomeIcon icon={faClock} />
          <Text>{time.toLocaleTimeString('en-US', { hour12: true })}</Text>
        </HStack>

        <HStack spacing={2} mr={4}>
          <FontAwesomeIcon icon={faCloudSun} />
          <WeatherComponent />
        </HStack>

        <Menu>
          <MenuButton
            as={IconButton}
            icon={<FontAwesomeIcon icon={faBell} />}
            variant="ghost"
            colorScheme="whiteAlpha"
            position="relative"
            mr={4}
          >
            {unreadCount > 0 && (
              <Badge
                colorScheme="red"
                position="absolute"
                top="-1"
                right="-1"
                borderRadius="50%"
                px={2}
                py={0.5}
              >
                {unreadCount}
              </Badge>
            )}
          </MenuButton>

          <MenuList bg="gray.700" color="whiteAlpha.900" borderColor="gray.600">
            <Box px={3} py={2} fontWeight="bold">
              Notificaciones
            </Box>
            {notifications.length > 0 ? (
              notifications.map((notif, idx) => (
                <MenuItem
                  key={notif.id}
                  onClick={() => handleNotificationClick(idx)}
                  _hover={{ bg: "gray.600", color: "white" }}
                  icon={<FontAwesomeIcon icon={notif.type === 'email' ? faEnvelopeOpen : faBell} />}
                >
                  {notif.message}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled _hover={{ bg: "gray.700" }}>
                Sin notificaciones
              </MenuItem>
            )}
            <MenuDivider borderColor="gray.500" />
            <MenuItem onClick={markAllAsRead} _hover={{ bg: "gray.600", color: "white" }}>
              Marcar todas como leídas
            </MenuItem>
          </MenuList>
        </Menu>

        <Menu>
          <MenuButton
            as={Button}
            variant="ghost"
            colorScheme="whiteAlpha"
            rightIcon={<FontAwesomeIcon icon={faUserCircle} />}
          >
            {useInfoUserAuth?.data
              ? `${useInfoUserAuth.data.first_name} ${useInfoUserAuth.data.last_name}`
              : "Usuario"
            }
          </MenuButton>
          <MenuList bg="gray.700" color="whiteAlpha.900" borderColor="gray.600">
            <MenuItem icon={<FontAwesomeIcon icon={faUserCircle} />}>
              Perfil
            </MenuItem>
            <MenuDivider borderColor="gray.500" />
            <MenuItem
              icon={<FontAwesomeIcon icon={faSignOutAlt} style={{ color: "red" }} />}
              onClick={handleLogout}
            >
              Cerrar sesión
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};