import React from 'react';
import { Box, Flex, Text, Link as ChakraLink, Tooltip } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FaWpforms, FaWhatsapp } from 'react-icons/fa';

const BotWhatsappModule = ({ collapsed }) => {
  return (
    <Box p={2} mt={4} borderTop="1px solid" borderColor="gray.300">
      {/* Título del módulo solo si el sidebar está expandido */}
      {!collapsed && (
        <Text mb={2} fontWeight="bold" fontSize="md">
          BOT WhatsApp
        </Text>
      )}
      <Flex direction="column" gap={2}>
        {/* Tarjeta 1: BOT en vivo Whatsapp */}
        <Tooltip label="Configura las plantillas de mensajes para WhatsApp o correo." openDelay={500}>
          <ChakraLink
            as={Link}
            to="/panel/chat"
            bg="#f76a6a"
            p={2}
            borderRadius="md"
            display="flex"
            alignItems="center"
            textDecoration="none"
          >
            <Box as={FaWpforms} transform="scale(2)" mr={collapsed ? 0 : 2} />
            {!collapsed && <Text ml={2} color="white">BOT en vivo Whatsapp</Text>}
          </ChakraLink>
        </Tooltip>

        {/* Tarjeta 2: Enviar Mensaje WhatsApp */}
        <Tooltip label="Envía mensajes personalizados o plantillas aprobadas por WhatsApp." openDelay={500}>
          <ChakraLink
            as={Link}
            to="/panel/chat/whatsapp"
            bg="#25D366"
            p={2}
            borderRadius="md"
            display="flex"
            alignItems="center"
            textDecoration="none"
          >
            <Box as={FaWhatsapp} transform="scale(2)" mr={collapsed ? 0 : 2} />
            {!collapsed && <Text ml={2} color="white">Enviar Mensaje WhatsApp</Text>}
          </ChakraLink>
        </Tooltip>
      </Flex>
    </Box>
  );
};

export default BotWhatsappModule;